import {
    IBatchTimeOff,
    IBatchValidationRules,
    IPlanningReport,
    ITimeChartEvent,
    Weekday,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, Reducer, useEffect, useReducer, useState } from 'react';

import { patch } from '../../../api/utils';
import ErrorAlert from '../../common/ErrorAlert';
import Panel from '../../common/Panel';
import Scheduler from '../../common/scheduler/Scheduler';

import Summary from './Summary';
import BatchDailyWorkHours from './parameters/BatchDailyWorkHours';
import NoProjectDays from './parameters/NoProjectDays';
import StartEnd from './parameters/StartEnd';
import TimeOffs from './parameters/TimeOffs';
import reducer, { IAction, IState, SetLoading, UpdateReport } from './reducer';

const NO_PROJECT_DAY_CLASS = 'no-project';
const TIME_OFF_CLASS = 'time-off';

interface IProps {
    batchDailyWorkHours: number;
    batchValidationRules: IBatchValidationRules;
    batchID: number;
    batchTimeOffs: IBatchTimeOff[];
    createBatchTimeOffURI: string;
    csrfToken: string;
    endDate: string;
    noProjectDays: Weekday[];
    planBatchCurriculumURI: string;
    report: IPlanningReport;
    startDate: string;
    updateBatchURI: string;
    updateBatchCurriculumURI: string;
}

function timeOffsForScheduler(
    startDate: string,
    endDate: string,
    noProjectDays: Weekday[],
    timeOffs: IBatchTimeOff[],
): ITimeChartEvent[] {
    const events = [];

    /* eslint-disable @typescript-eslint/camelcase */
    noProjectDays.forEach((noProjectDay) => {
        const date = moment(startDate).day(noProjectDay);
        if (date.isBefore(startDate)) {
            date.add(1, 'week');
        }

        while (date.isSameOrBefore(endDate)) {
            events.push({
                className: NO_PROJECT_DAY_CLASS,
                color: null,
                duration: 1,
                end_date: moment(date)
                    .add(1, 'day')
                    .format('DD-MM-YYYY'),
                id: date.format('DD-MM-YYYY'),
                start_date: date.format('DD-MM-YYYY'),
                text: date.format('DD-MM-YYYY'),
                type: 'task',
            });
            date.add(1, 'week');
        }
    });

    events.push(
        ...timeOffs.map((timeOff) => ({
            className: TIME_OFF_CLASS,
            color: null,
            duration:
                moment(timeOff.end_date).diff(timeOff.start_date, 'days') + 1,
            end_date: moment(timeOff.end_date)
                .add(1, 'day')
                .format('DD-MM-YYYY'),
            id: `${timeOff.id}`,
            start_date: moment(timeOff.start_date).format('DD-MM-YYYY'),
            text: `Time off #${timeOff.id}`,
            type: 'task',
        })),
    );
    /* eslint-enable @typescript-eslint/camelcase */

    return events;
}

export default function Planner({
    batchDailyWorkHours,
    batchValidationRules,
    batchID,
    batchTimeOffs,
    createBatchTimeOffURI,
    csrfToken,
    endDate,
    noProjectDays,
    planBatchCurriculumURI,
    report,
    startDate,
    updateBatchURI,
    updateBatchCurriculumURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        batchID,
        createBatchTimeOffURI,
        csrfToken,
        dailyWorkHours: batchDailyWorkHours,
        endDate,
        loading: false,
        noProjectDays,
        report,
        startDate,
        timeOffs: batchTimeOffs,
        updateBatchCurriculumURI,
        updateBatchURI,
        validationRules: batchValidationRules,
    });

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        (async function(): Promise<void> {
            try {
                setError(null);
                dispatch(new SetLoading(true));

                const response = await patch<IPlanningReport>(
                    planBatchCurriculumURI,
                    csrfToken,
                    {},
                );
                dispatch(new UpdateReport(response));
            } catch (err) {
                setError(err.message);
            } finally {
                dispatch(new SetLoading(false));
            }
        })();
    }, [
        state.dailyWorkHours,
        state.endDate,
        state.noProjectDays,
        state.startDate,
        state.timeOffs,
    ]);

    return (
        <div className="row">
            <div className="col-lg-3 col-sm-12">
                <Panel icon="cogs" title="Parameters">
                    <div className="list-group">
                        <div className="list-group-item">
                            {error && <ErrorAlert error={error} />}

                            <Summary state={state} />
                        </div>

                        <div className="list-group-item">
                            <BatchDailyWorkHours
                                dispatch={dispatch}
                                state={state}
                            />
                        </div>

                        <div className="list-group-item">
                            <StartEnd dispatch={dispatch} state={state} />
                        </div>

                        <div className="list-group-item">
                            <NoProjectDays dispatch={dispatch} state={state} />
                        </div>

                        <div className="list-group-item">
                            <TimeOffs dispatch={dispatch} state={state} />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="col-lg-9 col-sm-12">
                <Scheduler
                    data={timeOffsForScheduler(
                        state.startDate,
                        state.endDate,
                        state.noProjectDays,
                        state.timeOffs,
                    )}
                    endDate={state.endDate}
                    startDate={state.startDate}
                    views={['year']}
                />

                <div className="d-flex gap-5">
                    <span className={`px-2 ${NO_PROJECT_DAY_CLASS}`}>
                        No-project day
                    </span>
                    <span className={`px-2 ${TIME_OFF_CLASS}`}>Time off</span>
                </div>
            </div>
        </div>
    );
}
